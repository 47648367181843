<template>
<div class="home">
<div class="big_banner">
	<img src="../assets/img/home.png" />
</div>
<div class="about">
	<div class="about_left">
		<h2>关于我们</h2>
		<div class="home_about_text">礼小鲁”是国通科技匠心之作，经山东省商务厅许可（备案号：3703000007），国联信托负责对预收资金实施100%监控，集加油、充电、汽车美容、线上零售于一体，为用户提供遍布全省的生活百态、特色消费及文旅服务，让齐鲁大地的文化底蕴与现代科技完美融合，实现一卡在手，畅享好客山东的独特魅力。公司自成立以来，秉持“客户至上、服务至诚”的核心理念，致力于创新驱动，不断追求卓越的产品力和极致的服务体验。</div>
		<div class="show_more">MORE>></div>
		<img class="home_jn" src="../assets/img/home_jn.png" />
	</div>
	<div class="about_right">
		<img class="home_sd" src="../assets/img/home_sd.png" />
		<ul class="about_right_list">
			<li><strong>商务厅</strong><p>备案</p></li>
			<li><strong><span style="color: #024DA4">100%</span>监管</strong><p>国联信托</p></li>
			<li><strong>四位一体化</strong><p>服务</p></li>
		</ul>
	</div>
</div>
<div class="serve">
	<h2>场景服务</h2>
	<div class="serve_img">
		<img v-if="state.banner_active == 1" src="../assets/img/home_banner/center_banner_1.png" />
		<div v-if="state.banner_active == 1" class="img_title">线上商城</div>
		<img v-if="state.banner_active == 2" src="../assets/img/home_banner/center_banner_2.png" />
		<div v-if="state.banner_active == 2" class="img_title">充电</div>
		<img v-if="state.banner_active == 3" src="../assets/img/home_banner/center_banner_3.png" />
		<div v-if="state.banner_active == 3" class="img_title">加油</div>
		<img v-if="state.banner_active == 4" src="../assets/img/home_banner/center_banner_4.png" />
		<div v-if="state.banner_active == 4" class="img_title">汽车美容</div>
		<ul class="serve_img_nav">
			<li>
				<router-link class="item_link" :to="{name: 'qilu_serves_mall', hash: '#top' }">
					<img class="serve_img_nav_icon" v-if="state.banner_active == 1" src="../assets/img/home_banner/tab/mall_active.png" @mouseover="state.banner_active = 1"/>
					<img class="serve_img_nav_icon" v-else src="../assets/img/home_banner/tab/mall.png" @mouseover="state.banner_active = 1"/>
				</router-link>
				<span v-if="state.banner_active == 1" style="color: #024DA4;">线上商城</span>
				<span v-else>线上商城</span>
			</li>
			<li>
				<router-link class="item_link" :to="{name: 'serves_light', hash: '#top' }">
					<img class="serve_img_nav_icon" v-if="state.banner_active == 2" src="../assets/img/home_banner/tab/light_active.png" @mouseover="state.banner_active = 2"/>
					<img class="serve_img_nav_icon" v-else src="../assets/img/home_banner/tab/light.png" @mouseover="state.banner_active = 2"/>
				</router-link>
				<span v-if="state.banner_active == 2" style="color: #024DA4;">充电</span>
				<span v-else>充电</span>
			</li>
			<li>
				<router-link class="item_link" :to="{name: 'serves_oil', hash: '#top' }">
					<img class="serve_img_nav_icon" v-if="state.banner_active == 3" src="../assets/img/home_banner/tab/oil_active.png" @mouseover="state.banner_active = 3"/>
					<img class="serve_img_nav_icon" v-else src="../assets/img/home_banner/tab/oil.png" @mouseover="state.banner_active = 3"/>
				</router-link>
				<span v-if="state.banner_active == 3" style="color: #024DA4;">加油</span>
				<span v-else>加油</span>
			</li>
			<li>
				<router-link class="item_link" :to="{name: 'serves_face', hash: '#top' }">
					<img class="serve_img_nav_icon" v-if="state.banner_active == 4" src="../assets/img/home_banner/tab/car_active.png" @mouseover="state.banner_active = 4"/>
					<img class="serve_img_nav_icon" v-else src="../assets/img/home_banner/tab/car.png" @mouseover="state.banner_active = 4"/>
				</router-link>
				<span v-if="state.banner_active == 4" style="color: #024DA4;">汽车美容</span>
				<span v-else>汽车美容</span>
			</li>
		</ul>
	</div>
</div>
<div class="news">
	<h2>新闻资讯</h2>
	<div class="news_group">
		<div class="news_top" v-for="(media_item, index) in state.news_list.top_news.data" :key="index">
			<router-link :to="{name: 'news_content', params:{id:media_item.id}}">
				<img :src="media_item.thumbnail" />
				<h3 class="title">{{media_item.title}}</h3>
				<div class="time">{{media_item.date}}</div>
			</router-link>
		</div>
		<ul class="news_list">
			<li class="news_top" v-for="(media_item, index) in state.news_list.news.data" :key="index">
				<router-link :to="{name: 'news_content', params:{id:media_item.id}}">
					<h3 class="title">{{media_item.title}}</h3>
					<div class="time">{{media_item.date}}</div>
				</router-link>
			</li>
		</ul>
	</div>
	<router-link class="item_link" :to="{name: 'news' }">
		<div class="show_more" style="margin:0 auto">MORE>></div>
	</router-link>

</div>
<div class="card">
	<h2>礼卡介绍</h2>
	<div class="card_img">
		<img :class="'child_'+state.card_img_0" src="../assets/img/card/card_1000.png" />
		<img :class="'child_'+state.card_img_1" src="../assets/img/card/card_2000.png" />
		<img :class="'child_'+state.card_img_2" src="../assets/img/card/card_4000.png" />
	</div>
	
	<div class="card_tools">
		<div class="svg_box" @click="card_left">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="35px" height="64px" >
				<path d="M32.333,2.435 L4.301,32.122 L32.333,59.827 " fill-rule="evenodd"  stroke="rgb(179, 179, 179)" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none"/>
			</svg>
		</div>
		<div class="svg_box" @click="card_right">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="35px" height="64px">
				<path d="M3.128,1.853 L31.241,31.625 L3.128,59.410 " fill-rule="evenodd"  stroke="rgb(179, 179, 179)" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none"/>
			</svg>
		</div>
	</div>
	<div class="card_link"><router-link to="/qilu/card/text" class="nav_link">购卡章程</router-link></div>
</div>

</div>
</template>
<script>
import axios from 'axios';
import center_nav from '@/components/center_nav.vue'
import {reactive,onMounted,onUnmounted ,toRaw} from 'vue';
//console.log(reactive)
export default {
components: {
	center_nav
},
name: 'MyComponent',
setup() {
	const state = reactive({
		banner_active:1,
		card_img_0:0,
		card_img_1:1,
		card_img_2:2,
		news_list:{
			top_news:{
				id:0,
				count:0,
				data:[]
			},
			news:{
				id:0,
				count:0,
				data:[]
			},
		},

	});
	// 生命周期钩子：组件挂载到DOM后执行
	onMounted(() => {
		get_categories();
	});
	// 生命周期钩子：组件即将卸载前执行
	onUnmounted(() => {
	});

	function get_categories(){
		axios.get('data/wp-json/gtsh_categories/v1/list').then(response => {
			state.categories_arr = response.data;
			for (let categories_key= 0; categories_key < response.data.length; categories_key++){
				if(response.data[categories_key].slug=="home"){
					state.news_list.news.id=response.data[categories_key].id
				}
			}
			get_list();
		}).catch(error => {
			console.error('There was an error!', error);
		});
		//state.categories_arr
	};

	function get_list(){
		axios.get('data/wp-json/gtsh_categories/v1/posts/'+state.news_list.news.id).then(response => {
			state.news_list.top_news.data = response.data.slice(0, 2);
			state.news_list.news.data = response.data.slice(2, 7);
			console.log(response)// 假设API直接返回posts数组
		}).catch(error => {
			console.error('There was an error!', error);
		});
	}

	function card_left(){
		state.card_img_0>0?state.card_img_0--:state.card_img_0 = 2
		state.card_img_1>0?state.card_img_1--:state.card_img_1 = 2
		state.card_img_2>0?state.card_img_2--:state.card_img_2 = 2
	}
	function card_right(){
		state.card_img_0<2?state.card_img_0++:state.card_img_0 = 0
		state.card_img_1<2?state.card_img_1++:state.card_img_1 = 0
		state.card_img_2<2?state.card_img_2++:state.card_img_2 = 0
	}
	
	// 返回给模板的响应式数据和函数  
	return {
		card_left,
		card_right,
		state
	};
}}
</script>
<style scoped>
.big_banner{width:100%}
.big_banner img{width:100%}

.about{width:1200px;margin:129px auto 0 auto;display: flex;justify-content:space-between}
.about .about_left{}
.about .about_left h2{font-size: 60px;margin-top:33px;margin-bottom:39px;font-weight: 400}
.about .about_left .home_about_text{font-size: 14px;line-height: 30px;color: #333333;width: 544px;margin-bottom:24px}
.about .about_left .home_jn{width:397px;height:283px;margin-top:64px}

.about .about_right{position: relative;}
.about .about_right .home_sd{width:599px;height:457px;}
.about .about_right .about_right_list{margin-top:124px;display: flex;justify-content:flex-end;position: absolute;right: 0;width: 940px;}
.about .about_right .about_right_list li{padding:0 30px;border-right: 1px solid #000;}
.about .about_right .about_right_list li:last-child{border-right:none}
.about .about_right .about_right_list li strong{font-size: 42px;font-weight: 400;}
.about .about_right .about_right_list li p{font-size: 20px;text-align: center;margin-top:15px}

.serve {margin-top:137px}
.serve h2{font-size: 52px;text-align: center;font-weight: 400}
.serve .serve_img{margin-top:41px;position: relative;}
.serve .serve_img>img{width:calc(100% - 97px);margin-right:97px}
.serve .serve_img .img_title{color:#FFFFFF;font-size: 52px;position: absolute;bottom:85px;left:190px;font-weight: 400;}
.serve .serve_img .serve_img_nav{position: absolute;width:700px;height:150px;background-color:#FFFFFF;display: flex;justify-content:center;bottom:-50px;right: 150px;padding:0 40px;box-shadow:0px -5px 5px 0px rgba(0,0,0,50%);}
.serve .serve_img .serve_img_nav li{font-size: 20px;width:25%;text-align: center;position: relative;display: flex;flex-direction:column;justify-content:center;align-items: center;cursor: pointer}
.serve .serve_img .serve_img_nav li .serve_img_nav_icon{height:44px;width:44px;margin-bottom:13px}
.serve .serve_img .serve_img_nav li::before{content:'';position: absolute;height: 60px;width:1px;background-color:#000000;right:0;top:50px}
.serve .serve_img .serve_img_nav li:last-child::before{display:none}

.news {margin-top:213px}
.news h2{font-size: 52px;text-align: center;font-weight: 400}
.news .news_group{width:1400px;margin:43px auto 0px auto;display: flex;flex-direction:row;align-items: flex-start }
.news .news_group .news_top{width:calc(34% - 15px);margin-right:15px}
.news .news_group .news_top img{width:100%}
.news .news_group .news_top .time{color: #999999}
.news .news_group .news_top .title{color: #4D4D4D;font-size: 20px;line-height: 34px;font-weight: 400;}
.news .news_group li.news_top .time{color: #999999;margin-bottom:20px}


.news .news_group .news_list{width:calc(32% - 30px);margin-left:30px;}
.news .news_group .news_list li{width:100%;border-top:1px dashed #939393;}
.news .news_group .news_list li:last-child{border-bottom:1px dashed #939393;}
.news .news_group .news_list li .title{color: #4D4D4D;font-size: 20px;line-height: 34px;font-weight: 400;padding-bottom:15px;padding-top:10px}
.news .news_group .news_list li .time{color: #999999;padding-bottom:10px}

.card {margin-top:154px;position: relative;}
.card h2{font-size: 52px;text-align: center;font-weight: 400}
.card .card_img{display: flex;flex-direction:row;justify-content:center;align-items: center;position: revert;margin-top:50px;height:380px;;}
.card .card_img img{transition: transform 0.5s ease-in-out,left 0.5s ease-in-out,z-index 0s;}
.card .card_img img.child_0{height:380px;transform: perspective(0.9em) rotateY(1deg) translateX(200px) translateZ(-2px);position: absolute;left:calc(50% - 900px);z-index: 1;}
.card .card_img img.child_1{height:380px;transform: perspective(200em) rotateY(0deg) translateX(0px) translateZ(0px);position: absolute;left:calc(50% - 300px);z-index: 10;}
.card .card_img img.child_2{height:380px;transform: perspective(0.9em) rotateY(-1deg) translateX(-200px) translateZ(-2px);position: absolute;left:calc(50% + 300px);z-index: 1;}
.card .card_link{margin-top:40px;margin-bottom:40px;text-align: center;font-size: 18px;}
.card .card_link a{color: #282828;}
.card .card_tools{position: absolute;top:0;bottom:0;left:0;right:0;}
.card .card_tools .svg_box{position: absolute;top: 50%;}
.card .card_tools .svg_box:nth-child(1){left:calc(50% - 780px);z-index: 20;}
.card .card_tools .svg_box:nth-child(2){right:calc(50% - 780px);z-index: 20;}


.show_more{width: 118px;height: 40px;line-height: 40px;background: #024DA4;color: #FFFFFF;text-align: center;}

</style>
