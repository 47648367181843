<template>
	<ul class="center_nav">
		<li v-for="(nav, nav_index) in nav_arr" :key="nav_index">
			<router-link :to="nav.url">{{nav.title}}</router-link>
		</li>
	</ul>
</template>

<script>
export default {
name: 'HelloWorld',
props: {
	nav_arr:[]
}
}
</script>

<style scoped>
.center_nav{margin:0 auto;height: 72px;}
.center_nav li{height: 72px;float:left;position: relative}
.center_nav li::before{content:'';position: absolute;width:1px;height: 22px;background-color: #EEEEEE;top: 25px;right: 0;}
.center_nav li:last-child::before{content:'';display:none}
.center_nav li a{height: 72px;line-height: 72px;text-align:center;display: inline-block;color: #666666;font-size: 16px;}
.center_nav li a.router-link-active {color: #024DA4;}





@media only screen and (min-width: 1200px) {
	.center_nav{width:1200px}
	.center_nav li{width:150px;}
	.center_nav li a{width:150px;}
}
@media only screen and (max-width: 1200px) {
	.center_nav{width:980px}
	.center_nav li{width:120px;}
	.center_nav li a{width:120px;}
}
@media only screen and (max-width: 980px) {
	.center_nav{width:720px}
	.center_nav li{width:100px;}
	.center_nav li a{width:100px;}
}
@media only screen and (max-width: 720px) {
	.center_nav{width:100%}
	.center_nav li{width:80px;}
	.center_nav li a{width:80px;}
}
@media only screen and (max-width: 480px) {
	.center_nav{width:100%}
	.center_nav{display:none}
}


</style>
