import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
	{path: '/',name: 'home',component: HomeView},
	{
		path: '/qilu',
		props: true,
		children: [{
			path: '/qilu/serves',
			name: 'qilu_serves',
			component: () => import('../views/qilu/ServesView.vue'),
			children: [{
				path: '/qilu/serves/mall',
				name: 'qilu_serves_mall',
				component: () => import('../views/qilu/serves/MallView.vue'),
			},{
				path: '/qilu/serves/light',
				name: 'serves_light',
				component: () => import('../views/qilu/serves/LightView.vue'),
			},{
				path: '/qilu/serves/face',
				name: 'serves_face',
				component: () => import('../views/qilu/serves/FaceView.vue'),
			},{
				path: '/qilu/serves/oil',
				name: 'serves_oil',
				component: () => import('../views/qilu/serves/OilView.vue'),
			}]
		},{
			path: '/qilu/card',
			name: 'qilu_card',
			component: () => import('../views/qilu/CardView.vue'),
			children: [{
				path: '/qilu/card/list',
				name: 'qilu_card_list',
				component: () => import('../views/qilu/card/CardListView.vue'),
			},{
				path: '/qilu/card/text',
				name: 'qilu_card_text',
				component: () => import('../views/qilu/card/CardTextView.vue'),
			}]
		}]
	},{
		path: '/news',
		name: 'news',
		component: () => import('../views/NewsView.vue'),
	},{
		path: '/news_content/:id',
		name: 'news_content',
		component: () => import('../views/NewsContentView.vue'),
		props: true
	},
	{path: '/about',name: 'about',component: () => import('../views/AboutView.vue')}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
