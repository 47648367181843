<template>
<div class="top_nav_group">
	<nav class="top_nav">
		<router-link class="top_logo" to="/"><img src="@/assets/logo.png" /></router-link>
		<div class="toggler_nav_group">↓</div>
		<ul class="nav_group" >
			<li class="nav_item"><router-link class="nav_link" to="/">首页</router-link></li>
			<li class="nav_item qilu_mall" @mouseover="showMenu" @mouseout="hideMenu">
				<router-link class="nav_link" to="/qilu/card/list">礼小鲁</router-link>
				<div v-show="showChild" class="dropdown-menu">
					<ul class="child_group" @mouseover="showMenu">
						<li class="child_item"><router-link class="child_link" to="/qilu/card/list">礼卡介绍</router-link></li>
						<li class="child_item"><router-link class="child_link" to="/qilu/serves/mall">场景服务</router-link></li>
					</ul>
				</div>
			</li>
			<li class="nav_item"><router-link class="nav_link" to="/news">新闻资讯</router-link></li>
			<li class="nav_item"><router-link class="nav_link" to="/about">关于我们</router-link></li>
		</ul>
	</nav>
</div>
<router-view/>


<div class="footer_group">
	<div class="footer_list_group">
		<div class="list_left">
			<ul class="list_group">
				<li class="list_tiem"><router-link class="item_link" to="/about">关于我们</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'about', hash: '#top_about' }">企业简介</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'about', hash: '#center_about'}">企业资质</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'about', hash: '#book_about'}">风控体系</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'about', hash: '#enterprise'}">企业文化</router-link></li>
			</ul>
			<ul class="list_group">
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'qilu_serves_mall', hash: '#top' }">场景服务</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'qilu_serves_mall', hash: '#top' }">线上商城</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'serves_light', hash: '#top' }">充电</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'serves_oil', hash: '#top' }">加油</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'serves_face', hash: '#top' }">汽车美容</router-link></li>
			</ul>
			<ul class="list_group">
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'news'}">新闻资讯</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'news', hash: '#news' }">公司动态</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'news', hash: '#media' }">媒体聚焦</router-link></li>
			</ul>
			<ul class="list_group">
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'qilu_card_list', hash: '#top' }">礼卡介绍</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'qilu_card_list', hash: '#top' }">礼卡展示</router-link></li>
				<li class="list_tiem"><router-link class="item_link" :to="{name: 'qilu_card_text', hash: '#top' }">购卡章程</router-link></li>
			</ul>
		</div>
		<div class="list_right">
			<div class="link">
				<select>
					<option >友情链接</option>
				</select>
			</div>
			<div class="code_group">
				<div class="code_item">
					<img src="@/assets/img/footer_right_1.png" />
					<span>礼遇齐鲁APP下载</span>
				</div>
				<div class="code_item">
					<img src="@/assets/img/footer_right_2.png" />
					<span>关注官方微信</span>
				</div>
			</div>
		</div>
	</div>
	<div class="footer_bottom">山东国通融惠科技有限公司  版权所有 备案号：<a href="https://beian.miit.gov.cn">鲁ICP备19020692号-1</a></div>
</div>
</template>

<script setup>
import { ref } from 'vue';
const showChild = ref(false);

// 定义鼠标移入事件处理函数  
function showMenu() {
	//console.log('show_menu!');
	showChild.value = true;
};

// 定义鼠标移出事件处理函数
function hideMenu() {  
	//console.log('hide_menu!');
	showChild.value = false;
};
</script>

<style>
html,body,h1,h2,h3,h4,p,ul,li{margin: 0px;padding: 0px;font-family: Microsoft YaHei;}
body{background-color: #FBFBFB;}
li{list-style-type: none;}
a{text-decoration: none;}
h2{font-weight: 400}

/* 中间的大图 */
.big_banner{width:100%}



</style>
<style scoped>
#app .top_logo{}
#app .top_logo img{height:69px;margin:15px 0 8px 0}
#app .top_nav_group{background-color: #FBFBFB;width:100%}
#app .top_nav_group .top_nav{height: 92px;display: flex;flex-direction:row;justify-content:flex-start ;align-items: center;margin:0 190px 0 190px;}
#app .nav_group{margin-left:auto;margin-right:0}
#app .nav_group .nav_item{float:left;height:92px;position: relative;}
#app .nav_group .nav_item a.nav_link{float:left;height:92px;line-height:92px;width: 125px;color: #1A1A1A;text-align: center;}
#app .nav_group .nav_item a.router-link-active{background: #024DA4;color: #FFFFFF;}
#app .nav_group .nav_item a.router-link-active.qilu_mall{background: #024DA4;color: #FFFFFF;}

#app .nav_group .nav_item.router-link-active.qilu_mall{display:none}
#app .nav_group .nav_item.router-link-active.qilu_list{display:none}

#app .top_nav .toggler_nav_group{margin-left:auto;margin-right:0;height:92px;line-height:92px;width:92px;background: #024DA4;color: #FFFFFF;text-align: center;}

#app .dropdown-menu {position: absolute;top:99%;left: 0;width: 125px;background-color: #fff;z-index:10;}
#app .dropdown-menu .child_group{height:72px;line-height:72px;}
#app .dropdown-menu .child_group .child_item {height: auto;height:72px;line-height:72px;}
#app .dropdown-menu .child_group .child_item a.child_link{float:left;height:72px;line-height:72px;width: 125px;color: #1A1A1A;text-align: center;}
#app .dropdown-menu .child_group .child_item a.router-link-active {color: #FFFFFF;}

#app .footer_group{padding-top:80px;background: #FFFFFF}
#app .footer_group .footer_list_group{margin:0 auto;display: flex;flex-direction:row;}
#app .footer_group .footer_list_group .list_tiem{margin:0 auto;}
#app .footer_group .footer_list_group .list_left{width:66%;display: flex;flex-direction:row;height: 220px;border-right: 1px solid #999493;margin-bottom:130px}
#app .footer_group .footer_list_group .list_left .list_group{width:25%}
#app .footer_group .footer_list_group .list_left .list_group .list_tiem{height:36px;line-height:36px;width:100%}
#app .footer_group .footer_list_group .list_left .list_group .list_tiem a.item_link{height:36px;line-height:36px;width:100%;color: #333333;font-size: 16px;display: inline-block;}

#app .footer_group .footer_list_group .list_right{width:calc(34% - 40px);margin-left:40px;height:350px;display: flex;flex-direction:column;justify-content:flex-start;align-items: center}
#app .footer_group .footer_list_group .list_right .link{width:calc(100% - 100px);margin-bottom:20px;}
#app .footer_group .footer_list_group .list_right .link select{width:100% }

#app .footer_group .footer_list_group .list_right .code_group{display: flex;flex-direction:row;justify-content: center;}
#app .footer_group .footer_list_group .list_right .code_group .code_item{display: flex;flex-direction:column;justify-content:flex-start;align-items: center;width:50%}
#app .footer_group .footer_list_group .list_right .code_group .code_item img{width:70%}
#app .footer_group .footer_list_group .list_right .code_group .code_item span{color: #333333;font-weight: bold;height: 30px;line-height: 30px;font-size: 16px;margin-top:5px}

#app .footer_group .footer_bottom{text-align: center;background: #000000;height: 105px;line-height: 105px;color: #FFFFFF;font-size: 16px;}
#app .footer_group .footer_bottom a{color: #FFFFFF;}


@media only screen and (min-width: 1200px) {
	#app .top_nav_group::before{content:'min-width: 1200px';position: absolute;color:#FFF;
		/*
		text-shadow: 0 1px #024DA4, 1px 0 #024DA4, -1px 0 #024DA4, 0 -1px #024DA4;
		*/
	}
	/*
	#app .top_nav_group .top_nav{width:1200px}
	*/
	#app .footer_group .footer_list_group{width:1200px}
	#app .top_nav_group{position: relative;}
	#app .top_nav .toggler_nav_group{display:none}
}
@media only screen and (max-width: 1200px) {
	#app .top_nav_group::before{content:'max-width: 1200px';position: absolute;color:#FFF;
		/*
		text-shadow: 0 1px #024DA4, 1px 0 #024DA4, -1px 0 #024DA4, 0 -1px #024DA4;
		*/
	}
	/*
	#app .top_nav_group .top_nav{width:980px}
	*/
	#app .footer_group .footer_list_group{width:980px}
	#app .top_nav_group{position: relative;}
	#app .top_nav .toggler_nav_group{display:none}
}
@media only screen and (max-width: 980px) {
	#app .top_nav_group::before{content:'max-width: 980px';position: absolute;color:#FFF;
		/*
		text-shadow: 0 1px #024DA4, 1px 0 #024DA4, -1px 0 #024DA4, 0 -1px #024DA4;
		*/
	}
	#app .top_nav_group{position: relative;}
	/*
	#app .top_nav_group .top_nav{width:720px}
	*/
	#app .footer_group .footer_list_group{width:720px}
	#app .top_nav .toggler_nav_group{display:none}
	#app .footer_group .footer_list_group .list_right .code_item span{font-size: 14px;}
	#app .footer_group .footer_list_group .list_left .list_group .list_tiem a.item_link{font-size:14px;}
	#app .footer_group .footer_bottom{font-size:14px;}
}
@media only screen and (max-width: 720px) {
	#app .top_nav_group::before{content:'max-width: 720px';position: absolute;color:#FFF;
		/*
		text-shadow: 0 1px #024DA4, 1px 0 #024DA4, -1px 0 #024DA4, 0 -1px #024DA4;
		*/
	}
	#app .top_nav_group{position: relative;}
	#app .top_nav_group .top_nav{width:100%}
	#app .top_nav_group .nav_group{display:none}
	#app .top_nav .toggler_nav_group{display: inline-block;}
	#app .footer_group .footer_list_group {width:100%}
	#app .footer_group .footer_list_group .list_right{display:none}
	#app .footer_group .footer_list_group .list_left {width:100%;border-right:none}
	#app .footer_group .footer_list_group .list_left .list_group .list_tiem a.item_link{font-size:14px;}
	#app .footer_group .footer_bottom{font-size:12px;}
}
@media only screen and (max-width: 480px) {
	#app .top_nav_group::before{content:'max-width: 480px';position: absolute;color:#FFF;
		/*
		text-shadow: 0 1px #024DA4, 1px 0 #024DA4, -1px 0 #024DA4, 0 -1px #024DA4;
		*/
	}
	#app .top_nav_group{position: relative;}
	#app .top_nav_group .top_nav{width:100%}
	#app .top_nav_group .nav_group{display:none}
	#app .top_nav .toggler_nav_group{display: inline-block;}
	#app .footer_group .footer_list_group {width:100%}
	#app .footer_group .footer_list_group .list_right{display:none}
	#app .footer_group .footer_list_group .list_left {width:100%;border-right:none}
	#app .footer_group .footer_list_group .list_left .list_group .list_tiem a.item_link{font-size:14px;}
	#app .footer_group .footer_bottom{font-size:12px;}
}
/*
#app .bg-primary{}
#app .bg-primary .navbar-expand-lg .navbar-collapse{}
#app .bg-primary .navbar-expand-lg .navbar-collapse{background-color: #FF0;}
#app .bg-primary .container-fluid{height: 92px;padding:0}
#app .bg-primary .container-fluid .navbar-collapse {}
#app .bg-primary .container-fluid .navbar-collapse .navbar-nav{background-color: #024DA4; }
#app .bg-primary .container-fluid .navbar-collapse .navbar-nav .nav-item {height: 92px;line-height: 92px; }
#app .bg-primary .container-fluid .navbar-collapse .navbar-nav .nav-item a{color: #1A1A1A;padding:0;width: 125px;text-align: center; }
#app .bg-primary .container-fluid .collapsed .navbar-toggler-icon{color: #1A1A1A;background-color: #024DA4; }
*/


</style>
